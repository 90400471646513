import { _global_domain } from "../domain-address"

const AddOrder = async(id_user, id_product, date_receipt, time_receipt, name, phone_number, address_delivery, anonymous, postcard, postcard_text, another_person, name_another_person, phone_number_another_person, delivery, completed, discount_points, points) => {

    return await fetch(_global_domain + "/add_order", {
        method: 'POST',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({fields: {
          id_user,
          id_product, 
          date_receipt, 
          time_receipt, 
          name, 
          phone_number, 
          address_delivery, 
          anonymous, 
          postcard, 
          postcard_text, 
          another_person, 
          name_another_person, 
          phone_number_another_person, 
          delivery, 
          completed, 
          discount_points, 
          points}})
    })
        .then(response => response.json())

}
export default AddOrder