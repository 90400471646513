const AlbumItem = ({ 
    setSelectImage,
    resetSelectImage, 
    resetMoveImage, 
    img, 
    prod_id, 
    img_id, 
    img_index, 
    prod_index, 
    select_image }) => {


    const toggleSelectImage = () => {
        (select_image.img_id !== -1 && select_image.img_id === img_id)
            ? resetSelectImage()
            : setSelectImage({ prod_id, img_id, img_index, prod_index })

        resetMoveImage()
    }

    return <div
        onClick={toggleSelectImage}
        className={"album-item " + ((select_image.img_id === img_id && select_image.prod_id === prod_id) ? "select-item" : "")}>
        <img src={img} alt="" />
    </div>
}

export default AlbumItem