import { _global_domain } from "../domain-address"

const GetContacts = (telegram) => fetch(_global_domain + "/get_contacts", { 
        method: 'POST',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({telegram})
})
        .then(response => response.json())

export default GetContacts