import { _global_domain } from "../domain-address"

const GetProduct = async(id) => {

    return await fetch(_global_domain + "/get_product", {
        method: 'POST',
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ id })
    })
        .then(response => response.json())
}

export default GetProduct