import { _global_domain } from "../domain-address"

const DeleteCategory = async(id) => {

    return await fetch(_global_domain + "/delete_category", {
        method: 'POST',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({id})
    })
        .then(response => response.json())

}
export default DeleteCategory