import { _global_domain } from "../domain-address"

const NewProduct = async (name, price, imgs, ctgs) => {

    return await fetch(_global_domain + "/add_product", {
        method: 'POST',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({fields: {name, price, imgs, ctgs}})
    })
        .then(response => response.json())
}
export default NewProduct