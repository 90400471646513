import { _global_domain } from "../domain-address"

const GetProfile = async(id) => {
    

    return await fetch(_global_domain + "/get_profile", {
        method: 'POST',
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({id})
    })
        .then(response => response.json())
    
}

export default GetProfile