import { FormControl, MenuItem, Select } from "@mui/material"

const SelectTime = ({order_time, setOrderTime}) => {

    const option_select = [
        {value: 1, label: "с 08:00 до 10:00"},
        {value: 2, label: "с 10:00 до 12:00"},
        {value: 3, label: "с 12:00 до 14:00"},
        {value: 4, label: "с 14:00 до 16:00"},
        {value: 5, label: "с 16:00 до 18:00"},
        {value: 6, label: "с 18:00 до 20:00"},
        {value: 7, label: "с 20:00 до 22:00"}
    ]

    return <FormControl className="time-order" fullWidth>
    <Select onChange={(e) => setOrderTime(e.target.value)} value={order_time} defaultValue={1}>
        {option_select.map(opt => <MenuItem key={opt.value} value={opt.value}>{opt.label}</MenuItem>)}
    </Select>
</FormControl>
}

export default SelectTime