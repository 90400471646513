import { useEffect, useState } from "react"
import { NavLink, useNavigate } from "react-router-dom"
import Loading from "../fixed-elements/Loading"
import CheckAdmin from "../../queries/CheckAdmin"

const AdminPanel = () => {

    const [isUserAdmin, setIsUserAdmin] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        let tg = window.Telegram.WebApp;
        if (tg.initDataUnsafe.user) // ВНИМАНИЕ
        CheckAdmin(tg.initDataUnsafe.user.id).then(response => { // ВНИМАНИЕ 
            setIsUserAdmin(response.result)
            !response.result && navigate("/")
        })
    }, [navigate])
    return isUserAdmin ? <div className="admin-panel-container">
        <h1>Админ-панель</h1>
        <div className="main-block">
            <NavLink to={"orders"} className="block contacts-block">Заказы</NavLink>
            <NavLink to={"contacts"} className="block contacts-block">Контакты</NavLink>
            <NavLink to={"ctgs"} className="block categories-block">Категории</NavLink>
            <NavLink to={"products"} className="block products-block">Товары</NavLink>
        </div>
    </div>
    : <Loading />
}

export default AdminPanel