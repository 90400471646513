
import { useDispatch, useSelector } from "react-redux";
import { Suspense, lazy, useEffect } from "react";
import { SET_PRODUCTS } from "../../reducers/types";
import Loading from "../fixed-elements/Loading";

const CategoryBlock = lazy(() => import("../category-block/CategoryBlock"));

const Home = () => {

    const categories = useSelector(s => s.app.categories)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch({type: SET_PRODUCTS, payload: []})
    }, [dispatch])

    return <main>
        {categories.filter(c => c.in_home).map(c => <Suspense key={c.id} fallback={<Loading />}><CategoryBlock ctg={c} /></Suspense>)}
    </main>
}
export default Home