import { useDispatch, useSelector } from "react-redux"
import { NavLink, useNavigate } from "react-router-dom"
import { ADD_NEW_IMAGE, DELETE_PRODUCT, SET_PRODUCTS } from "../../../reducers/types"
import { useEffect, useState } from "react"
import ItemName from "./ItemName"
import ItemPrice from "./ItemPrice"
import AlbumItem from "./AlbumItem"
import ImageControlBlock from "./ImageControlBlock"
import MoveImageControl from "./MoveImageContol"
import CategoryItem from "./CategoryItem"
import SelectionBlock from "./SelectionBlock"
import UploadImage from "../../../queries/UploadImage"
import GetAllProducts from "../../../queries/GetAllProducts"
import DeleteProduct from "../../../queries/DeleteProduct"
import EditProduct from "../../../queries/EditProduct"
import Loading from "../../fixed-elements/Loading"
import CheckAdmin from "../../../queries/CheckAdmin"

const ProductsPage = () => {
    const navigate = useNavigate()
    const products = useSelector(s => s.app.products)
    const dispatch = useDispatch()
    const [select_image, setSelectImage] = useState({ prod_id: -1, img_id: -1, img_index: -1, prod_index: -1 })
    const [onMoveId, setOnMoveId] = useState({ prod_id: -1, img_id: -1 })
    const [isUserAdmin, setIsUserAdmin] = useState(false)

    const uploadImageHandler = async (files, prod) => {
        await UploadImage(files).then(resp_imgs => {
            console.log(prod)
            let new_imgs = [
                ...prod.imgs.map(el => el.img),
                ...resp_imgs.file_urls
            ]
            EditProduct("imgs", prod.id, new_imgs).then(resp_edit => {
                resp_edit &&
                dispatch({
                    type: ADD_NEW_IMAGE, payload: {
                        id: prod.id,
                        imgs: resp_imgs.file_urls // Массив из картинок
                    }
                })
            })
        });

    }

    const deleteProductHandler = (id) => {
        DeleteProduct(id).then(response =>{
            response.result &&
            dispatch({ type: DELETE_PRODUCT, payload: id })
        }
        )
    }

    const resetSelectImage = () => setSelectImage({ prod_id: -1, img_id: -1, img_index: -1, prod_index: -1 })
    const resetMoveImage = () => setOnMoveId({ prod_id: -1, img_id: -1 })

    useEffect(() => {

        let tg = window.Telegram.WebApp;
        if (tg.initDataUnsafe.user) // ВНИМАНИЕ
        CheckAdmin(tg.initDataUnsafe.user.id).then(response => { /// Внимание
            setIsUserAdmin(response.result)
            !response.result && navigate("/")
        })

        GetAllProducts(2000, 0).then(response => {
            dispatch({ type: SET_PRODUCTS, payload: response.result.data })
        })
    }, [dispatch, navigate])

    return isUserAdmin ? <div className="products-page-container admin-block-container">
        <div onClick={() => navigate(-1)} className="back-block">
            <svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 -960 960 960" width="30"><path d="M560-240 320-480l240-240 56 56-184 184 184 184-56 56Z" /></svg>
            <p>Назад</p>
        </div>
        <div className="products-control">
            <h1 className="heading">Товары</h1>
            <NavLink to="new" className="add_but">Добавить товар</NavLink>
        </div>
        <div className="products-list">
            {products &&
                products.map((p, prod_index) => <div className="product-item" key={p.id}>
                    <ItemName name={p.name} id={p.id} />
                    <ItemPrice price={p.price} id={p.id} />
                    <div className="album">
                        {
                            p.imgs.map((el, img_index) => <AlbumItem
                                key={img_index}
                                setSelectImage={setSelectImage}
                                resetSelectImage={resetSelectImage}
                                resetMoveImage={resetMoveImage}
                                img={el.img}
                                prod_id={p.id}
                                img_id={el.id}
                                img_index={img_index}
                                prod_index={prod_index}
                                select_image={select_image}
                            />)
                        }
                    </div>
                    <div className="img-control">
                        <div className="control-block">
                            <div className="file-upload-but control-item">
                                <input name={"file" + p.id} id={"file" + p.id} type="file" placeholder="Да" multiple
                                    onChange={(e) => uploadImageHandler(e.target.files, p)} />
                                <label htmlFor={"file" + p.id} >
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path fill="#fff" d="M440-320v-326L336-542l-56-58 200-200 200 200-56 58-104-104v326h-80ZM240-160q-33 0-56.5-23.5T160-240v-120h80v120h480v-120h80v120q0 33-23.5 56.5T720-160H240Z" /></svg>
                                </label>
                            </div>
                            {select_image.prod_id === p.id && <ImageControlBlock
                                select_image={select_image}
                                resetMoveImage={resetMoveImage}
                                resetSelectImage={resetSelectImage}
                                onMoveId={onMoveId}
                                prod={p}
                                setOnMoveId={setOnMoveId}
                            />}
                        </div>
                        {onMoveId.prod_id === p.id && <MoveImageControl
                            products={products}
                            setSelectImage={setSelectImage}
                            select_image={select_image}
                        />}
                    </div>
                    <p className="categories-block-heading">Категории</p>
                    <div className="categories-block">
                        {p.ctgs.map(id => <CategoryItem
                            key={id}
                            prod={p}
                            ctg_id={id}
                        />)}
                        <SelectionBlock
                            prod={p}
                            ctgs={p.ctgs}
                        />
                    </div>
                    <div onClick={() => deleteProductHandler(p.id)} className="delete-but">Удалить</div>
                </div>)
            }
        </div>
    </div>
    : <Loading />
}

export default ProductsPage