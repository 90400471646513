import { FormControl, MenuItem, Select } from "@mui/material"
import { useSelector } from "react-redux"
import { useState } from "react"

const SelectionBlock = ({ prod_id, ctgsProduct, setCtgsProduct}) => {

    const [select_category, setSelectCategory] = useState('')
    const [onSelectCategory, setOnSelectCategory] = useState(-1)
    
    const categories = useSelector(s => s.app.categories)

    const resetSelectCategory = () => setSelectCategory('')

    const toggleSelectCategory = () => {
        (onSelectCategory === -1 || onSelectCategory !== prod_id)
            ? setOnSelectCategory(prod_id)
            : setOnSelectCategory(-1)
    }

    const saveCategoryForProduct = () => {

        if (select_category && !ctgsProduct.some(c => c.id === select_category.id)) {
            setCtgsProduct([...ctgsProduct, select_category])
            toggleSelectCategory()
            resetSelectCategory()
        }
    }


    return <FormControl>
        <div className="selection-block">
            {onSelectCategory === prod_id && <div className="add-ctg-block">
                <Select
                    className="selection-input"
                    value={select_category}
                    sx={{ minWidth: 150 }}
                    onChange={(e) => setSelectCategory(e.target.value)}
                >
                    {categories.map(c => <MenuItem key={c.id} value={c}>{c.name}</MenuItem>)}
                </Select>
                <div onClick={saveCategoryForProduct} className="category-item but">
                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path fill="#444" d="M840-680v480q0 33-23.5 56.5T760-120H200q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h480l160 160Zm-80 34L646-760H200v560h560v-446ZM480-240q50 0 85-35t35-85q0-50-35-85t-85-35q-50 0-85 35t-35 85q0 50 35 85t85 35ZM240-560h360v-160H240v160Zm-40-86v446-560 114Z" /></svg>
                </div>
            </div>}
            <div onClick={toggleSelectCategory} className="category-item but">
                {onSelectCategory === prod_id
                    ? <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M200-440v-80h560v80H200Z" /></svg>
                    : <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z" /></svg>
                }
            </div>
        </div>
    </FormControl>
}

export default SelectionBlock