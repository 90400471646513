import { _global_domain } from "../domain-address"

const CheckAdmin = async(id) => {

    return await fetch(_global_domain + "/is_admin", {
        method: 'POST',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({id})
    })
        .then(response => response.json())

}
export default CheckAdmin