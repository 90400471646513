import UploadImage from "../../../queries/UploadImage";
import ImageItem from "./ImageItem"

const LoadImagesContainer = ({ images_links, setImagesLinks }) => {

    const uploadImage = async files => {

        UploadImage(files).then(response => setImagesLinks(response.file_urls));
    }

    return <div className="load-images-container">
        <h2>Фото</h2>
        <div className="images-block">
            {
                images_links.length
                    ? images_links.map((link, i) => <ImageItem key={i} link={link} />)
                    : <p>Изображений нет</p>
            }
        </div>
        <label className="load-images-but">
            <input name="file" id="file" type="file" placeholder="Да" multiple
                onChange={(e) => uploadImage(e.target.files)} />
            Загрузить изображения</label>
        
    </div>
}

export default LoadImagesContainer