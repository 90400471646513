import { _global_domain } from "../domain-address";

const UploadImage = async (files) => {

    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
        formData.append('files[]', files[i]);
    }

    return await fetch(_global_domain + "/upload", {
        method: 'POST',
        body: formData
    })
    .then(response => response.json())
}

export default UploadImage