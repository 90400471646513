import { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { LOAD_BASKET } from '../../reducers/types'
import { useDispatch } from 'react-redux'
import GetStatusPay from '../../queries/GetStatusPay'

const ThankPage = () => {

    const S_PENDING = "pending"
    const S_CANCELED = "canceled"
    const S_PAID = 'paid'

    const dispatch = useDispatch()
    const [statusPay, setStatusPay] = useState(S_PENDING)

    const renderText = () => {
        if(statusPay === S_PENDING) return "Ожидание оплаты"
        if(statusPay === S_CANCELED) return "Заказ отменен"
        if(statusPay === S_PAID) return "Спасибо, ваш заказ принят!"
        return false
    }

    const renderImg = () => {
        if(statusPay === S_PENDING) return require('../../assets/img/loading.png')
        if(statusPay === S_CANCELED) return require('../../assets/img/error.png')
        if(statusPay === S_PAID) return require('../../assets/img/thank.png')
        return false
    }

    const getStatusPayHandler = useCallback(async (id_payment, url_payment) => {
        await GetStatusPay(id_payment).then(response => {
            if(response.result){
                if (response.result.status === "paid") setStatusPay(S_PAID)
                if (response.result.status === "canceled") setStatusPay(S_CANCELED)
                if (response.result.status === "pending") {
                    setStatusPay(S_PENDING)
                    let timerCheck = setTimeout(() => {

                        getStatusPayHandler(id_payment, url_payment)
                        clearTimeout(timerCheck)
                    }, 4000);
                    if(localStorage.getItem("payment-first-dff-store")) {
                        window.location = url_payment
                        localStorage.removeItem("payment-first-dff-store")
                    }
                    
                }
            }
        })
    }, [])

    useEffect(() => {
        let id_payment = localStorage.getItem("payment-id-dff-store") || -1
        let url_payment = localStorage.getItem("payment-url-dff-store") || -1

        id_payment === -1 ? setStatusPay(S_PAID) : setStatusPay(S_PENDING)

        getStatusPayHandler(id_payment, url_payment)

    }, [dispatch, getStatusPayHandler])

    useEffect(() => {
        if (statusPay !== S_PENDING) {
            localStorage.removeItem("basket-dff-store")
            localStorage.removeItem("payment-id-dff-store")
            localStorage.removeItem("payment-url-dff-store")
            localStorage.removeItem("payment-first-dff-store")
            dispatch({ type: LOAD_BASKET, payload: [] })
        }

    }, [statusPay, dispatch])

    return <div className="thank-page">
        <img className={statusPay === S_PENDING ? "loading" : ""} src={renderImg()} alt="thank" />
        <h2>{renderText()}</h2>
        { statusPay !== S_PENDING && <Link to="/" className="back-to-main">На главную</Link>}
    </div>
}

export default ThankPage