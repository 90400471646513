import { _global_domain } from "../domain-address"

const CompleteOrder = async(id) => {

    return await fetch(_global_domain + "/complete_order", {
        method: 'POST',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({id})
    })
        .then(response => response.json())

}
export default CompleteOrder