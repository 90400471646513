import { _global_domain } from "../domain-address"

const GetPriceFromAddress = async(address) => {

    return await fetch(_global_domain + "/geo", {
        method: 'POST',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({address})
    })
        .then(response => response.json())

}
export default GetPriceFromAddress