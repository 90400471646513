import { _global_domain } from "../domain-address"

const EditContacts = async(adress, contact_phone, telegram) => {

    return await fetch(_global_domain + "/edit_contacts", {
        method: 'POST',
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            fields: {adress, contact_phone, telegram}
        })
    })
        .then(response => response.json())

}
export default EditContacts