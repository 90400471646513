const PointsSelectBlock = ({activeButtonPoints, setActiveButtonPoints}) => {


    const changeActPoints = (act) => {
        if (act === 0) {
            setActiveButtonPoints(0)
        } else {
            setActiveButtonPoints(1)
        }
    }

    return <div className="points-select-act">
        <div 
        className={"points-select-button" + (activeButtonPoints === 0 ? " active" : "")}
        onClick={() => changeActPoints(0)}>Накопить</div>
        <div 
        className={"points-select-button" + (activeButtonPoints === 1 ? " active" : "")}
        onClick={() => changeActPoints(1)}>Списать</div>
    </div>
}

export default PointsSelectBlock