import { _global_domain } from "../domain-address"

const GetStatusPay = async (id) => {

    return await fetch(_global_domain + "/status_pay", {
        method: 'POST',
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({id})
    })
    .then(response => response.json())
}

export default GetStatusPay