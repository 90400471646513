import { Checkbox, FormControlLabel, FormGroup, TextField } from "@mui/material"
import PhoneInput from "react-phone-input-2"

const Checkboxs = ({ client_number_another, setClientNumberAnother,
    client_name_another, setClientNameAnother,
    client_other, setClientOther,
    client_postcard, setClientPostcard,
    client_anonim, setClientAnonim,
    client_postcard_text, setClientPostcardText,
    errors,
    id_select_deliver }) => {

    return <FormGroup className="checkbox-container">
        <FormControlLabel className="checkbox-label" control={<Checkbox className="checkbox" value={client_other} onChange={(e) => setClientOther(!client_other)} />} label="Получатель другой человек" />

        <div className={"checkbox-hide-block hide-block-other " + (client_other ? "open" : "")}>
            <TextField
                className="name-order input-type"
                label="Имя получателя"
                onChange={(e) => setClientNameAnother(e.target.value)}
                value={client_name_another} fullWidth />
                {(!errors.name_another && client_other) ? <p className="error">Заполните имя</p> : ""}

            <p className="heading">Номер получателя</p>

            <PhoneInput
                value={client_number_another}
                onChange={e => setClientNumberAnother(e)}
                containerClass="number-order input-type"
                country='ru' />
                {(!errors.number_another && client_other) ? <p className="error">Заполните номер</p> : ""}
        </div>

        <FormControlLabel className="checkbox-label" control={<Checkbox className="checkbox" value={client_postcard} onChange={(e) => setClientPostcard(!client_postcard)} />} label="Открытка" />

        <div className={"checkbox-hide-block hide-block-postcard " + (client_postcard ? "open" : "")}>
            <TextField
                className="name-order input-type"
                label="Текст открытки"
                onChange={(e) => setClientPostcardText(e.target.value)}
                value={client_postcard_text} fullWidth />
                {(!errors.postcard_text && client_postcard) ? <p className="error">Заполните текст открытки</p> : ""}
        </div>
        <FormControlLabel className="checkbox-label" control={<Checkbox className="checkbox" value={client_anonim} onChange={(e) => setClientAnonim(!client_anonim)} />} label="Анонимно" />
    </FormGroup>
}

export default Checkboxs