import { _global_domain } from "../domain-address"

const GetProducts = async(ctgs, limit, offset, ids) => {

    return await fetch(_global_domain + "/get_products", {
        method: 'POST',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ctgs, limit, offset, ids})
    })
        .then(response => response.json())
}

export default GetProducts