
import { NavLink } from "react-router-dom"
import errorimg from "../../assets/img/errorimg.png"

const CategoryBlockItem = ({product}) => {
    return <NavLink to={"/p/" + product.id}>
        <img src={product.imgs[0]?product.imgs[0].img:errorimg} alt="" loading="lazy" />
        <p className="price">{product.price} ₽</p>
        <p className="discription">{product.name}</p>
    </NavLink>
}

export default CategoryBlockItem